import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';

export const reducerName = 'deviceStore';

const DEVICE_GETALL_RESPONSE = 'DEVICE_GETALL_RESPONSE';

const initialState = {
    device: {}
};

export const actionCreators = {
    getAll: () => async(dispatch, getState) => {
        const deviceId = localStorage.getItem('deviceId');
        if(deviceId){
            axios({
                method: 'GET',
                url: `${config.server}/api/device/${deviceId}`,
                withCredentials: true,
                auth: createAuth(getState)
            }).then(response => {
                dispatch({type: DEVICE_GETALL_RESPONSE, data: response.data})
            }).catch(error => {
    
            });
        }
    },
};

const reducerMethods = {
    DEVICE_GETALL_RESPONSE: (state, action) => {
        return{
            ...state,
            device: action.data
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};