import {actionCreators as appActions} from "./AppStore";
import {actionCreators as routerActions} from "./RouterActions";

export const reducerName = 'auth';

const AUTH_SET_ORGANIZATION_ID = 'AUTH_SET_ORGANIZATION_ID';
const AUTH_SET_API_KEY = 'AUTH_SET_API_KEY';
const AUTH_SET_DEVICE_ID = 'AUTH_SET_DEVICE_ID';

const orgId = localStorage.getItem('orgId');
const apiKey = localStorage.getItem('apiKey');
const deviceId = localStorage.getItem('deviceId');

const initialState = {
    orgId: orgId,
    apiKey: apiKey,
    deviceId: deviceId
};

export const actionCreators = {
    setOrgId: (id) => async(dispatch, getState) => {
        dispatch({type: AUTH_SET_ORGANIZATION_ID, id});
    },
    setApiKey: (apiKey) => async(dispatch, getState) => {
        dispatch({type: AUTH_SET_API_KEY, apiKey});
    },
    setDeviceId: (deviceId) => async(dispatch, getState) => {
        dispatch({type: AUTH_SET_DEVICE_ID, deviceId});
    },
    
    
    
    login: () => async(dispatch, getState) => {
        dispatch(appActions.refresh());
        dispatch(appActions.refreshSlow());
        dispatch(routerActions.navigate('/'));
    }
};

const reducerMethods = {
    AUTH_SET_ORGANIZATION_ID: (state, action) => {
        localStorage.setItem('orgId', action.id);
        return{
            ...state,
            orgId: action.id
        };
    },
    AUTH_SET_API_KEY: (state, action) => {
        localStorage.setItem('apiKey', action.apiKey);
        return{
            ...state,
            apiKey: action.apiKey
        }
    },
    AUTH_SET_DEVICE_ID: (state, action) => {
        localStorage.setItem('deviceId', action.deviceId);
        return{
            ...state,
            deviceId: action.DeviceId 
        }
    }
    

};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action) || state;
    return state;
};