import axios from 'axios';
import config from '../config';
import {createAuth} from '../Helpers';

const OFFICE_CALENDAR_GETALL_RESPONSE = 'OFFICE_CALENDAR_GETALL_RESPONSE';

const initialState = {
    officeCalendars : [ ]
};

export const actionCreators = {
    getAllOfficeCalendarToday: () => async(dispatch, getState) => {
        axios({
            method: 'GET',
            url: `${config.server}/api/Office365/getPeopleWithAppointmentsNow`,
            withCredentials: true,
            auth: createAuth(getState)
        }).then(response => {
            dispatch({type: OFFICE_CALENDAR_GETALL_RESPONSE, data: response.data})
        }).catch(error => {

        });
    }
};

const reducerMethods = {
    OFFICE_CALENDAR_GETALL_RESPONSE: (state, action) => {

        /*
        const noget = action.data;
        debugger;

        const noget1 = noget.filter(aftale => {
            const now = new Date();
            const end = new Date(aftale.end);
            const start = new Date(aftale.start);

            console.log(`start: ${start}, now: ${now}, end: ${end}`);

            const isBeforeEnd = now <= end;
            const isAfterStart = now >= start;

            return isBeforeEnd && isAfterStart;
        });
        debugger;
        */
        return{
            ...state,
            officeCalendars: action.data
        }
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    const method = reducerMethods[action.type];
    if (method) return method(state, action);
    return state;
};