import React from 'react';
import Menu from '../Menu';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
import Unit from './Unit';
import ResourceCalendar from './ResourceCalendar';
import moment from 'moment';
import './Resource.css';
import TeamChooser from './TeamChooser';
import TimeSelectForm from "./TimeSelectForm";
import { actionCreators as resourceActions } from '../../store/ResourceStore';



const Resource = props => {
    const now = moment();
    const units = props.resState.units;
    const bookings = props.resState.bookings;

    const resourceActions = props.resourceActions;
    
    const categories = props.resState.categories;

    const activeCategory = props.resState.activeCategory;
    const showCategories = props.resState.showCategories;

    const bookingsNow = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const futureBookings = bookings.filter(x => now.isBefore(x.startTime))
        .sort((a,b) => {
            a = new Date(a.startTime);
            b = new Date(b.startTime);
            return a < b ? -1 : a > b ? 1 : 0
        });


    return(
        <div className="App">
            <Menu />
            <div className='Resource'>
                <div className='padding-content'>
                    <div className='units-container'>
                        <div className='booking-header-wrapper'>
                        <h2 className='book-header'>Book udstyr</h2>
                        {
                            !showCategories && (<><span className='back-arrow' onClick={resourceActions.setToCategoryView}>&#8249;</span><h3 className='back-header' onClick={resourceActions.setToCategoryView}> Tilbage til kategorier</h3></>)
                        }
                        </div>
                        <div  className='units'>
                            {
                                categories.map((category) => {
                                    
                                    if(showCategories){
                                        return (
                                            <div className='unit-category' key={`category-${category}`} onClick={() => {resourceActions.setToUnitView(category)}}>
                                                {category}
                                            </div>
                                        )
                                    }
                                    return;
                                })
                            }
                            {
                                units.map((u,i) => {
                                    const nextBooking = futureBookings.find(x => x.unitId === u.id);
                                    const currentBooking = bookingsNow.find(x => x.unitId === u.id);

                                    if(!showCategories && u.category == activeCategory){
                                        return(
                                            <Unit
                                                key={i}
                                                unit={u}
                                                currentBooking={currentBooking}
                                                nextBooking={nextBooking}
                                            />
                                        );
                                    }
                                    return;


                                })
                            }
                        </div>
                        <div className='clearfix'/>
                    </div>
                    <ResourceCalendar />
                </div>
                <TimeSelectForm/>
                <TeamChooser/>
            </div>
        </div>
    )
};

export default connect(
    state => ({resState: state[ResourceStore.reducerName]}),
    dispatch => ({
        resourceActions: bindActionCreators(resourceActions, dispatch),
    })
)(Resource);